@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,700;1,300;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,700&display=swap');

* {
    font-family: 'Roboto', 'Cormorant Garamond', 'sans-serif';
}

body {
    padding: 0;
    color: #323d4c;
    background: #fdfdfd;
    margin: 0;
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: 5em;
    line-height: 1.2em;
    padding: 20px 0;
    font-weight: 700;
    color: #323d4c;
}

h1.rtb {
    font-size: 2em;
    line-height: 0em;
    font-weight: 700;
    color: #323d4c;
}


h2 {
    font-size: 42px;
    line-height: 1.2em;
    padding: 20px 0;
    font-weight: 100;
}

h2.rtb {
    font-size: 1em;
    line-height: 0em;
    font-weight: 700;
    color: #323d4c;
    margin: 10px 0 0 0;
}

p {
    font-size: 1em;
    line-height: 1.75em;
    font-weight: 300;
}

a {
    transition: all 0.2s;
    color: #293038;
    text-decoration: none;
}

a:hover {
    color: cornflowerblue;
}

span.now {
    font-weight: 700;
}

.quote {
    font-size: 1.5em;
    font-weight: 600;
}

.quote p {
    line-height: 1.6em;
    margin-top: 10px;
}

ul {
    list-style-type: none;
    margin: 20px 0 64px 0;
    padding: 0;
}

ul.rtb {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex; /* Arrange items in a row */
    flex-wrap: wrap; /* Allow wrapping if there are too many cards */
    gap: 10px; /* Space between cards */
    justify-content: center; /* Center the cards horizontally */
}

.container {
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
}

.container .large {
    max-width: 1300px;
    margin: auto;
    box-sizing: border-box;
}

.container .small {
    margin: auto;
    max-width: 760px;
    box-sizing: border-box;
}

.column {
    box-sizing: border-box;
}

.column .left {
    width: 0%;
    display: inline-block;
    padding-right: 30px;
}

.column .right {
    width: 99%;
    display: inline-block;
    padding-right: 50px;
    padding-left: 1px;
    box-sizing: border-box;
}

.bottom {
    display: none;
}

.projects .project-item {
    font-size: 1em;
}

.page-title h3 {
    padding: 0;
    margin: 0;
    display: inline-block;
    font-size: 1em;
    margin-right: 10px;
    font-weight: 800;
}

.label {
    color: #293038;
    font-size: 0.8em;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: 500;
}

h3 {
    font-size: 1.8em;
}

h4 {
    font-size: 1.4em;
}

.page-title h1,
.page-title h2 {
    margin: 0;
    padding: 0;
    font-size: 2em;
}

.resume {
    margin-top: 0px;
}

.resume li {
    margin-bottom: 20px;
}

.role {
    font-weight: 700;
    font-size: 0.9em;
    margin: 4px 0;
}

.resume .role2 {
    font-weight: 500;
    font-style: italic;
    font-size: 0.7em;
    margin: 4px 0;
}

.resume .date {
    font-size: 0.9em;
    color: rgba(34, 36, 39, 0.5);
}

.resume .company {
    font-size: 0.9em;
    font-weight: 300;
}

.social {
    float: right;
    padding-right: 100px;
}

.social ul {
    margin-left: -10px;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.social li {
    display: inline-block;
}

.linkedin a,
.email a {
    display: block;
    height: 20px;
    width: 20px;
    padding: 10px;
    background-position: center;
    background-repeat: no-repeat;
    filter: invert(24%) sepia(16%) saturate(671%) hue-rotate(175deg) brightness(91%) contrast(98%);
    background-size: 2em;
}

.linkedin a {
    background-image: url('assets/linkedin.svg');
}

.email a {
    background-image: url('assets/email.svg');
}

.small img,
.large img {
    width: 100%;
    margin-top: 20px;
}

input.rtb {
    margin: 0 0 10px 0;
}

div.rtb {
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: center;
}

.rtb-action-log {
    height: 25em;
    overflow-y: scroll;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    background-color: #fafafa;
    width: 100%; /* Ensures log container takes full width */
}

.rtb-action-log ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.rtb-action-log li {
    display: block; /* Each log entry on its own line */
    width: 100%; /* Ensure each entry takes the full width */
    white-space: nowrap; /* Prevent text wrapping within entries */
    overflow: hidden;
    text-overflow: ellipsis; /* Clip overflowing text with ellipsis */
    margin-bottom: 6px; /* Add space between log entries */
    line-height: 1.5em; /* Consistent spacing for readability */
}

/* Styling for individual card items */
.current-cards {
    display: inline-block; /* Inline block to allow horizontal alignment */
    margin: 0; /* Remove margin-bottom to prevent stacking */
}

.current-info p {
    line-height: 0.6em;
}


.card-display {
    display: inline-block; /* Inline block to allow horizontal alignment */
    text-align: center; /* Center the content within the card */
}

.card-image-wrapper {
    display: block; /* Ensures each card image takes up a full row */
}

/* Card image styling */
.card-display img {
    width: 80px;
    /* Set card width */
    height: auto;
    /* Maintain aspect ratio */
    border: 1px solid black;
    /* Optional: Add border to the cards */
    border-radius: 5px;
    /* Optional: Round the corners of the cards */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    /* Optional: Add shadow */
}

.card-image {
    width: 80px; /* Adjust as needed */
    height: auto;
    border: 1px solid black; /* Optional border */
    border-radius: 5px; /* Optional rounded corners */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow */
}

.card-image-container {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center items horizontally */
    gap: 10px; /* Space between cards */
    max-width: 100%;
}

.current-cards-section {
    height: 100px;
    margin-bottom:10px; 
}


.card-image-container img {
    height: 100px;
    /* Control image size */
    width: auto;
}

button {
    margin: 15px;
    /* Add space between the button and the cards */
}



@media screen and (max-width: 768px) {
    .column .left {
        width: 100%;
        padding-right: 0;
    }

    .column .right {
        width: 100%;
        padding-left: 0;
    }

    .social {
        padding-right: 25px;
    }
}